import React from "react";
import {connect} from "react-redux";
import { retrieveData, createData } from "../actions/fetch-data";
import Header from "../components/headers/header";
import { v4 as uuid } from 'uuid';

import { PROMO } from '../constants/endpoints';
import {
  CREATE_PROMO,
  FETCH_PROMO,
} from '../constants/constants';
import './content.scss';

class PromoPage extends React.Component {
  constructor(props) {
    super(props);

    this.createPromoCode = this.createPromoCode.bind(this);
  }

  componentDidMount(){
    this.props.retrieveData(PROMO, FETCH_PROMO);
  }

  createPromoCode(e) {
    this.props.createData(PROMO, CREATE_PROMO);
  }

  render() {
    const { promo } = this.props.state;

    return (
      <div>
        <Header title="Content" subtitle="Manage site-wide content" />
        <div className="content">

          <div className="l-box">
            <div className="pure-g">
              <div className="pure-u-1">
                <h3>Promo codes</h3>
                <p>To generate a new code click the button below. Subscribers can enter their code when upgrading their account</p>
                <button className="pure-button pure-button-primary" onClick={this.createPromoCode}>Generate Code</button>
              </div>
            </div>
          </div>

          <div className="l-box">
            <div className="pure-g">
              <div className="pure-u-1">
                <table className="pure-table pure-table-striped">
                  <thead>
                  <tr>
                    <th>Code</th>
                    <th>Created</th>
                    <th>Redeemed?</th>
                    <th>Redeemed By</th>
                  </tr>
                  </thead>
                  <tbody>

                  {promo.list.length > 0 ? promo.list.map((item) => <tr key={item._id.$oid}>
                    <td>{item.code}</td>
                    <td>{(new Date(item.created_at)).toLocaleString()}</td>
                    <td>{item.expired ? <span className="success">Yes</span> : 'No'}</td>
                    <td>{item.redeemed_by || ''}</td>
                  </tr>) : <tr><td colSpan="4">No codes created</td></tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  nextState => ({ state: nextState }),
  { retrieveData, createData }
)(PromoPage)

